@charset "UTF-8";
/* 2023 new career page */
.career-kv {
  position: relative;
}
.career-kv::before {
  display: block;
  content: "";
  width: 100%;
  aspect-ratio: 1500/640;
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  position: absolute;
  top: -20px;
  left: 0;
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .career-kv::before {
    background-position-y: -60px;
  }
}
.career-kv__inner {
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .career-kv__inner {
    background-image: url(../img/common/bg-gra-top.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.career-kv__h1 {
  padding: 80px 0 0 10vw;
}
.career-kv__h1--main {
  font-size: 54px;
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .career-kv__h1--main {
    font-size: 36px;
  }
}
.career-kv__h1--en {
  display: block;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.027em;
}
@media screen and (max-width: 767px) {
  .career-kv__h1--en {
    font-size: 14px;
  }
}
.career-kv__ul {
  display: flex;
  justify-content: center;
  gap: 2px;
  margin: 0;
  padding: 0;
  margin: 160px auto 0;
  width: calc(100% - 40px - 40px);
}
@media screen and (max-width: 767px) {
  .career-kv__ul {
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    width: calc(100% - 20px - 20px);
  }
}
.career-kv__li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% - 1px) / 2);
  aspect-ratio: 723.816/554.6784;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  position: relative;
  overflow: hidden;
  transition: background-size 0.3s linear;
}
@media screen and (max-width: 767px) {
  .career-kv__li {
    width: 100%;
  }
}
.career-kv__li:hover {
  background-size: 102%;
}
.career-kv__li::after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 38, 98, 0.55);
  position: absolute;
  top: 0;
  left: 0;
}
.career-kv__li:first-of-type {
  background-image: url(../img/career/link01.jpg);
}
.career-kv__li:last-of-type {
  background-image: url(../img/career/link02.jpg);
}
.career-kv__link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  color: #fff;
  text-align: center;
  text-decoration: none;
}
.career-kv__link:hover {
  text-decoration: none;
}
.career-kv__link--en {
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 0.06em;
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .career-kv__link--en {
    font-size: 36px;
  }
}
.career-kv__link--main {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16em;
}
@media screen and (max-width: 767px) {
  .career-kv__link--main {
    font-size: 12px;
  }
}

.top-pamphlet {
  display: flex;
  justify-content: center;
  margin-top: 200px;
  padding: 70px 20px;
  background: #c4c4cd;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-pamphlet {
    display: block;
    margin-top: 100px;
    padding: 50px 20px;
    text-align: center;
  }
}
.top-pamphlet::after {
  display: block;
  content: "";
  width: 100%;
  height: 20px;
  background-color: #fff;
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: 2;
}
.top-pamphlet-inner {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .top-pamphlet-inner {
    display: block;
  }
}
.top-pamphlet-img {
  max-width: 137px;
  margin: 0 35px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 767px) {
  .top-pamphlet-img {
    margin: 0 auto 20px;
  }
}
.top-pamphlet-ttl {
  margin: 0 0 15px;
  color: #4f2d7f;
}
.top-pamphlet-btn a {
  display: block;
  position: relative;
  width: 260px;
  padding: 13px 20px;
  border: 1px solid #4f2d7f;
  text-decoration: none;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .top-pamphlet-btn a {
    width: auto;
  }
}
.top-pamphlet-btn a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #4f2d7f;
  border-right: 1px solid #4f2d7f;
  transform: translateY(-50%) rotate(45deg);
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .top-pamphlet-btn a:hover {
    color: #fff;
    background: #4f2d7f;
  }
  .top-pamphlet-btn a:hover:after {
    border-color: #fff;
  }
}

/* 2023 new career page end */
.kv {
  position: relative;
  margin: 0 0 70px;
  padding: 0 50px 75px 50px;
  /* background: $primary; */
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .kv {
    margin: 0 0 30px;
    padding: 0 20px 40px;
  }
}
.kv-img {
  margin: 0 -50px 0 0;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .kv-img {
    margin: 0 -20px 0 0;
  }
}
.kv-img img {
  max-height: 515px;
  transform: translateX(-25px);
}
@media screen and (max-width: 767px) {
  .kv-img img {
    transform: none;
  }
}
.kv-inner-01 {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 75px;
  left: 50%;
  width: 1200px;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .kv-inner-01 {
    right: 20px;
    bottom: 40px;
    left: 20px;
    width: auto;
    transform: none;
  }
}
.kv-inner-02 {
  position: relative;
  width: 475px;
  height: 220px;
  padding: 60px 0 0 80px;
  color: #fff;
  /* background: $primary; */
  z-index: 0;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .kv-inner-02 {
    width: 62.27vw;
    height: auto;
    padding: 30px 0 20px 35px;
  }
}
.kv-inner-02:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -100vw;
  bottom: -75px;
  left: 0;
  /* background: $primary; */
  background-image: url(../img/common/kv_title_bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .kv-inner-02:after {
    bottom: 0;
  }
}
.kv-en {
  line-height: 1.3;
  margin: 0 0 20px;
  color: #00a7b5;
  font-size: 34px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .kv-en {
    margin: 0 0 10px;
    font-size: 23px;
  }
}
.kv-ttl {
  margin: 0;
  color: #00a7b5;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .kv-ttl {
    font-size: 11px;
  }
}
.kv-ttl span {
  display: block;
  margin: 0 0 10px;
  font-size: 12px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .kv-ttl span {
    font-size: 9px;
  }
}

.career-wrap {
  max-width: 1100px;
  margin: 0 auto 90px;
  padding: 100px 125px;
  background: #fff;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .career-wrap {
    margin: 0 20px 50px;
    padding: 30px 20px;
  }
}

.career-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
.career-nav li {
  position: relative;
  padding-left: 20px;
}
@media screen and (max-width: 767px) {
  .career-nav li {
    padding-left: 15px;
  }
}
.career-nav li:before {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 100%;
}
@media screen and (max-width: 767px) {
  .career-nav li:before {
    width: 6px;
    height: 6px;
  }
}
.career-nav-notes {
  position: relative;
  padding-left: 1em;
}
.career-nav-notes:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}

.career-detail {
  margin: 100px 0 0;
}
@media screen and (max-width: 767px) {
  .career-detail {
    margin: 60px 0 0;
  }
}
.career-detail h3 {
  margin: 0 0 35px;
  color: #4f2d7f;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .career-detail h3 {
    margin: 0 0 20px;
    font-size: 16px;
  }
}
.career-detail-inner {
  margin: 0 0 30px;
  padding: 30px 0 0;
  border-top: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  .career-detail-inner {
    margin: 0 0 20px;
    padding: 20px 0 0;
  }
}
.career-detail h4 {
  margin: 0 0 15px;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .career-detail h4 {
    font-size: 14px;
  }
}
.career-detail h5 {
  margin: 0 0 5px;
  font-size: 13px;
}
@media screen and (max-width: 767px) {
  .career-detail h5 {
    font-size: inherit;
  }
}
.career-detail-btn {
  list-style: none;
  margin: 40px 0 0;
  padding: 0;
  text-align: center;
}
.career-detail-btn li + li {
  margin: 15px 0 0;
}
.career-detail-btn li a {
  display: inline-block;
  width: 380px;
  padding: 23px 15px;
  border: 1px solid #4f2d7f;
  color: #fff;
  text-decoration: none;
  background: #4f2d7f;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .career-detail-btn li a {
    display: block;
    width: auto;
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .career-detail-btn li a:hover {
    color: #4f2d7f;
    background: #fff;
  }
}

#career .form-step {
  border: none;
}
@media screen and (max-width: 767px) {
  #career .form-step {
    padding: 0;
  }
}

.career-form-lead {
  margin: 0 0 80px;
}
@media screen and (max-width: 767px) {
  .career-form-lead {
    margin: 0 0 40px;
  }
}
.career-form-lead dl {
  margin: 0 0 2em;
}
.career-form-lead dl dt {
  margin: 0 0 5px;
  color: #4f2d7f;
  font-size: 15px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .career-form-lead dl dt {
    font-size: inherit;
  }
}
.career-form-lead dl dd {
  margin: 0;
}

.career-form-privacy {
  height: 240px;
  overflow: auto;
  margin: 80px 0;
  padding: 30px;
  border: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  .career-form-privacy {
    margin: 50px 0;
    padding: 20px;
  }
}
.career-form-privacy::-webkit-scrollbar {
  width: 4px;
}
.career-form-privacy::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #515151;
}
.career-form-privacy h2 {
  margin: 0 0 50px;
  color: #4f2d7f;
  font-size: 15px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .career-form-privacy h2 {
    margin: 0 0 30px;
    font-size: inherit;
  }
}
.career-form-privacy h3 {
  font-size: inherit;
}
.career-form-privacy h4 {
  font-size: inherit;
}