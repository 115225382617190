@charset "utf-8";

@import "tools/mixins";
@import "tools/var";

/* 2023 new career page */

.career-kv{
  position: relative;
  &::before{
    display: block;
    content: "";
    width: 100%;
    aspect-ratio: 1500/640;
    background-image: url(../img/common/bg-gra-top.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 0;
    @include mq(sp) {
      background-position-y: -60px;
    }
  }
  &__inner{
    position: relative;
    z-index: 10;
    @include mq(sp) {
      background-image: url(../img/common/bg-gra-top.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__h1{
    padding: 80px 0 0 calc((150 / 1500) * 100vw);
    &--main{
      font-size: 54px;
      font-weight: 500;
      letter-spacing: .05em;
      @include mq(sp) {
        font-size: 36px;
      }
    }
    &--en{
      display: block;
      font-family: $font-gt;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.027em;
      @include mq(sp) {
        font-size: 14px;
      }
    }
  }
  &__ul{
    display: flex;
    justify-content: center;
    gap: 2px;
    margin: 0;
    padding: 0;
    margin: 160px auto 0;
    width: calc(100% - 40px - 40px);
    @include mq(sp) {
      align-items: center;
      flex-direction: column;
      margin-top: 80px;
      width: calc(100% - 20px - 20px);
    }    
  }
  &__li{
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - 1px) / 2);
    aspect-ratio: 723.816/554.6784;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    position: relative;
    overflow: hidden;
    transition: background-size 0.3s linear;
    @include mq(sp) {
      width: 100%;
    }
    &:hover{
      background-size: 102%;
    }
    &::after{
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(#002662,0.55);
      position: absolute;
      top: 0;
      left: 0;
    }
    &:first-of-type{
      background-image: url(../img/career/link01.jpg);
    }
    &:last-of-type{
      background-image: url(../img/career/link02.jpg);
    }
  }
  &__link{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    color: #fff;
    text-align: center;
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
    &--en{
      font-family: $font-gt;
      font-size: 50px;
      font-weight: 400;
      letter-spacing: .06em;
      line-height: 1.2;
      @include mq(sp) {
        font-size: 36px;
      }
    }
    &--main{
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.16em;
      @include mq(sp) {
        font-size: 12px;
      }
    }
  }
}

.top-pamphlet {
  display: flex;
  justify-content: center;
  margin-top: 200px;
  padding: 70px 20px;
  background: #c4c4cd;
  position: relative;
  @include mq(sp) {
    display: block;
    margin-top: 100px;
    padding: 50px 20px;
    text-align: center;
  }
  &::after{
    display: block;
    content: "";
    width: 100%;
    height: 20px;
    background-color: #fff;
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: 2;
  }
  &-inner {
    display: flex;
    align-items: center;
    @include mq(sp) {
      display: block;
    }
  }
  &-img {
    max-width: 137px;
    margin: 0 35px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    @include mq(sp) {
      margin: 0 auto 20px;
    }
  }
  &-ttl {
    margin: 0 0 15px;
    color: $primary;
  }
  &-btn {
    a {
      display: block;
      position: relative;
      width: 260px;
      padding: 13px 20px;
      border: 1px solid $primary;
      text-decoration: none;
      box-sizing: border-box;
      @include mq(sp) {
        width: auto;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        width: 8px;
        height: 8px;
        border-top: 1px solid $primary;
        border-right: 1px solid $primary;
        transform: translateY(-50%) rotate(45deg);
        transition: .3s;
      }
      &:hover {
        @include mq(pc) {
          color: #fff;
          background: $primary;
          &:after {
            border-color: #fff;
          }
        }
      }
    }
  }
}
/* 2023 new career page end */

.kv {
  position: relative;
  margin: 0 0 70px;
  padding: 0 50px 75px 50px;
  /* background: $primary; */
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    margin: 0 0 30px;
    padding: 0 20px 40px;
  }
  &-img {
    margin: 0 -50px 0 0;
    text-align: center;
    @include mq(sp) {
      margin: 0 -20px 0 0;
    }
    img {
      max-height: 515px;
      transform: translateX(-25px);
      @include mq(sp) {
        transform: none;
      }
    }
  }
  &-inner-01 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 75px;
    left: 50%;
    width: 1200px;
    transform: translateX(-50%);
    @include mq(sp) {
      right: 20px;
      bottom: 40px;
      left: 20px;
      width: auto;
      transform: none;
    }
  }
  &-inner-02 {
    position: relative;
    width: 475px;
    height: 220px;
    padding: 60px 0 0 80px;
    color: #fff;
    /* background: $primary; */
    z-index: 0;
    box-sizing: border-box;
    @include mq(sp) {
      width: 62.27vw;
      height: auto;
      padding: 30px 0 20px 35px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -100vw;
      bottom: -75px;
      left: 0;
      /* background: $primary; */
      background-image: url(../img/common/kv_title_bg.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: -1;
      @include mq(sp) {
        bottom: 0;
      }
    }
  }
  &-en {
    line-height: 1.3;
    margin: 0 0 20px;
    color: $secondary;
    font-size: 34px;
    font-family: $font-gt;
    font-weight: 500;
    letter-spacing: .1em;
    @include mq(sp) {
      margin: 0 0 10px;
      font-size: 23px;
    }
  }
  &-ttl {
    margin: 0;
    color: $secondary;
    font-size: 14px;
    @include mq(sp) {
      font-size: 11px;
    }
    span {
      display: block;
      margin: 0 0 10px;
      font-size: 12px;
      font-weight: 500;
      @include mq(sp) {
        font-size: 9px;
      }
    }
  }
}

.career-wrap {
  max-width: 1100px;
  margin: 0 auto 90px;
  padding: 100px 125px;
  background: #fff;
  box-sizing: border-box;
  @include mq(sp) {
    margin: 0 20px 50px;
    padding: 30px 20px;
  }
}
.career-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    position: relative;
    padding-left: 20px;
    @include mq(sp) {
      padding-left: 15px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 9px;
      left: 0;
      width: 8px;
      height: 8px;
      background: #000;
      border-radius: 100%;
      @include mq(sp) {
        width: 6px;
        height: 6px;
      }
    }
  }
  &-notes {
    position: relative;
    padding-left: 1em;
    &:before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.career-detail {
  margin: 100px 0 0;
  @include mq(sp) {
    margin: 60px 0 0;
  }
  h3 {
    margin: 0 0 35px;
    color: $primary;
    font-size: 24px;
    @include mq(sp) {
      margin: 0 0 20px;
      font-size: 16px;
    }
  }
  &-inner {
    margin: 0 0 30px;
    padding: 30px 0 0;
    border-top: 1px solid $line;
    @include mq(sp) {
      margin: 0 0 20px;
      padding: 20px 0 0;
    }
  }
  h4 {
    margin: 0 0 15px;
    font-size: 16px;
    @include mq(sp) {
      font-size: 14px;
    }
  }
  h5 {
    margin: 0 0 5px;
    font-size: 13px;
    @include mq(sp) {
      font-size: inherit;
    }
  }
  &-btn {
    list-style: none;
    margin: 40px 0 0;
    padding: 0;
    text-align: center;
    li {
      & + li {
        margin: 15px 0 0;
      }
      a {
        display: inline-block;
        width: 380px;
        padding: 23px 15px;
        border: 1px solid $primary;
        color: #fff;
        text-decoration: none;
        background: $primary;
        box-sizing: border-box;
        @include mq(sp) {
          display: block;
          width: auto;
          padding: 20px;
        }
        &:hover {
          @include mq(pc) {
            color: $primary;
            background: #fff;
          }
        }
      }
    }
  }
}

#career .form-step {
  border: none;
  @include mq(sp) {
    padding: 0;
  }
}
.career-form-lead {
  margin: 0 0 80px;
  @include mq(sp) {
    margin: 0 0 40px;
  }
  dl {
    margin: 0 0 2em;
    dt {
      margin: 0 0 5px;
      color: $primary;
      font-size: 15px;
      font-weight: bold;
      @include mq(sp) {
        font-size: inherit;
      }
    }
    dd {
      margin: 0;
    }
  }
}
.career-form-privacy {
  height: 240px;
  overflow: auto;
  margin: 80px 0;
  padding: 30px;
  border: 1px solid $line;
  @include mq(sp) {
    margin: 50px 0;
    padding: 20px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #515151;
  }
  h2 {
    margin: 0 0 50px;
    color: $primary;
    font-size: 15px;
    text-align: center;
    @include mq(sp) {
      margin: 0 0 30px;
      font-size: inherit;
    }
  }
  h3 {
    font-size: inherit;
  }
  h4 {
    font-size: inherit;
  }
}
